.task-list-container {
  margin: 0 15%;
  max-width: 1024px;
  margin: 0 auto;
}

.task {
  margin-bottom: 2rem;
}

.task .options {
  display: flex;
}

.task .options.justified {
  flex-direction: column;
}

.task .options > div,
.task .options > button {
  display: flex;
}

.task .options.justified > div,
.task .options.justified > button {
  justify-content: space-between;
}

.bn-container .bn-editor * {
  font-family: Archivo, sans-serif;
}