.container {
  padding: 3.2rem;
  max-width: 400px;
  margin: 0 auto;
}

.loginLink {
  margin-top: 1.2rem;
}

.resetPasswordButton {
  margin-top: 0.8rem;
}

.headingSection {
  text-align: center;
  margin-bottom: 2.4rem;
}
