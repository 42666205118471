body, html {
  height: 100%;
  margin: 0 40rem;
}

.login-container {
  max-width: 300px;
  width: 300px;
  margin: 0 auto;
}

.login-container .input {
  margin: 1rem 0;
}

.login-container .logo-container {
  margin: 2rem 0;
}

.login-container .logo-container img {
  width: 4rem;
  display: block;
}

.login-container .logo-container .app-title h1 {
  line-height: 4rem;
  margin: 0 1rem;
}