body,
html {
  height: 100%;
  margin: 0 !important;
  font-family: Archivo;
  overscroll-behavior: none;
}

.dark {
  background-color: #2f343c;
}

.bp5-tab:focus {
  outline: 0;
}

.app-container {
  display: flex;
}

.thread-list-container {
  overflow-y: auto;
  height: calc(100vh - 40px);
  min-width: 300px;
  max-width: 300px;
  text-overflow: ellipsis;
  background-color: #2f343c;
}

.thread-list-container.loading {
  height: calc(100vh);
}

.thread-list-container .app-title {
  margin: 2rem 0.8rem;
}

.thread-list-container .logo-container img {
  width: 2.5rem;
  margin: 1.5rem 0rem 0rem 1rem;
  display: block;
}

.chat-container {
  overflow-y: scroll;
  height: calc(100vh);
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100vh; /* Set app container to full height of the viewport */
  padding-bottom: 1rem;
}

.chat-container .thread-title {
  padding: 0.5rem 10%;
}

.chat-container .navigation {
  padding: 0.5rem 10%;
}

.list-item.selected {
  background-color: rgba(45, 114, 210, 0.1);
}

.message-container {
  flex: 1;
  overflow-y: auto;
  max-height: calc(
    100vh - 60px
  ); /* Adjust max-height to leave space for the input */
  margin-bottom: 10px;
  padding: 0.5rem 10%;
}

.message {
  background-color: #f5f8fa;
  padding: 8px;
  margin-bottom: 4px;
  border-radius: 4px;
}

.message a {
  display: inline-block;
  border-radius: 6px;
  padding: 0 1rem;
  line-height: 4rem;
  border: 1px solid grey;
  font-weight: bold;
  text-decoration: none;
}

.message a::before {
  content: '📄';
  font-size: 2rem;
}

.bp3-input-group {
  margin-top: auto; /* Stick the input to the bottom */
}

/* Additional styles for the input field */
.bp3-input {
  border-radius: 0;
}
.role {
  padding: 0 8px;
}

.non-ideal-state {
  height: 100vh;
}
