.container {
  max-width: 600px;
  max-height: calc(100vh);
  overflow: auto;
  margin: 0 auto;
  padding: 2.4rem;
}

.backButton {
  margin-bottom: 2.4rem;
}
